import {  storage } from "../../libs/firebase";
import {  ref, getDownloadURL, uploadBytesResumable, getMetadata   } from "firebase/storage";
import uuid from 'uuid';
import commonmcontext from '../../utils/common/firebasecontext';

const metajpg = {
  contentType: 'image/jpeg'
};

const metapng = {
    contentType: 'image/png'
  };
  

const firebaseContext = {
    ENTITY: 'entity',
    USER: 'user',
    GENERAL: 'general',
    ICON: 'icon',
    CATEGORY: 'category'
}

const file_extension = {
    jpg: '.jpg',
    png: '.png',
    pdf: '.pdf'
}

export default {
    async uploadFile(context, file, id) {
        return new Promise((resolve, reject) => {
            var response = null;
            let firecontext = firebaseContext.ENTITY;
            let firemeta = metajpg;
            let extension =  file_extension.jpg;
            try {
                switch (context) {
                    case commonmcontext.ENTITY:
                        firecontext = firebaseContext.ENTITY;
                        firemeta = metajpg;
                        extension = file_extension.jpg;
                    break;
                    case commonmcontext.USER:
                        firecontext = firebaseContext.USER
                        firemeta = metajpg;
                        extension = file_extension.jpg;
                    break;
                    case commonmcontext.GENERAL:
                        firecontext = firebaseContext.GENERAL;
                        firemeta = metajpg;
                        extension = file_extension.jpg;
                    break;
                    case commonmcontext.ICON:
                        firecontext = firebaseContext.ICON;
                        firemeta = metapng;
                        extension = file_extension.png;
                    break;
                    case commonmcontext.CATEGORY:
                        firecontext = firebaseContext.CATEGORY;
                        firemeta = metajpg;
                        extension = file_extension.jpg;
                    break;
                    default:
                        firecontext = firebaseContext.ENTITY;
                        firemeta = metajpg;
                        extension = file_extension.jpg;
                    break;
                }

                const public_id = id ? id : uuid.v4().toString();
                const storageRef = ref(storage, `${firecontext ? firecontext : 'exception'}/${public_id}/${uuid.v4().toString()}${extension}`);
                const uploadTask = uploadBytesResumable(storageRef, file, firemeta);
    
                uploadTask.on('state_changed', (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                      case 'paused':
                        console.log('Upload is paused');
                        break;
                      case 'running':
                        console.log('Upload is running');
                        break;
                    }
                }, (error)=> {
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            response = {
                                message: 'Unauthorized',
                                error: true
                            } 
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            response = {
                                message: 'Upload Canceled',
                                error: true
                            } 
                            break
                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            response = {
                                message: 'Storage Unknow',
                                error: true
                            } 
                            break;
                    }
                }, () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        response = downloadURL;
                        resolve({url: response, fullPath: uploadTask.snapshot.ref.fullPath, public_id: public_id});
                    });
                }
                )
            } catch (err) {
                response = null;
                reject(response);
            }
            
        })
    },

    async getFileMetadata(context, id, name) {
        let firecontext = firebaseContext.ENTITY;
        return new Promise((resolve, reject) => {
            try {
                switch (context) {
                    case commonmcontext.ENTITY:
                        firecontext = firebaseContext.ENTITY
                    break;
                    case commonmcontext.USER:
                        firecontext = firebaseContext.USER
                    break;
                    case commonmcontext.GENERAL:
                        firecontext = firebaseContext.GENERAL
                    break;
                    default:
                        firecontext = firebaseContext.ENTITY
                    break;
                }

                const pathReference = ref(storage, `${firecontext}/${id}/${name}`);
                getMetadata(pathReference).then((metadata) => {
                    resolve(metadata);
                })
            } catch (error) {
                reject(null);
            }
        })
    }
}

