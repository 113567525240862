const ENTITY = 1;
const USER = 2;
const GENERAL = 3;
const ICON = 4;
const CATEGORY = 5;

export default {
    ENTITY,
    USER,
    GENERAL,
    ICON,
    CATEGORY

}